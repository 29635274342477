.round{
  border-radius: 15px;
  /* margin-top: 5px; */
}

.position-img{
position:absolute;
top: 100%;
}
.position{
  position: fixed;
  top:52%;
  left:83%
}
.count-position{
  position: absolute !important;
  
  bottom: 40% !important;
  left: 15px !important;
  font-size: small !important;
  
 
}
.remove-Link{
  text-decoration: none; /* Remove underline on hover */
  cursor: pointer;
}
.remove-Link:hover{
  text-decoration: none; /* Remove underline on hover */
  cursor: pointer;
}
.overFlowText{
  word-Wrap:break-word;
  width: 100%;
  line-height:1.5;
}
.calendar_default_shadow_inner{
z-index: 0px;
}
.calendar-event {
  z-index: 10; 
  position: relative;
 
}

.search-estimate{
  position: absolute;
    z-index: 99;
    top: 30px;
    
    background-color:white; }
 
.input-border-bottom{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid gray;
  outline: none;
}
.color{
  color: red;
}
#css-1dimb5e-singleValue{
  
  color: rgb(149, 51, 51)  !important;;
  
}
.color{
  color: #86ecbe;
}
.bg-color{
  background-color:#86ecbe ;
}
.dose{
  font-size: 11px;
    font-weight: 700;
}
.dose1{
  font-size: 5px;
    font-weight: 700;
}
.input-border-bottom:focus(){
  outline: none;
}
.dp_event_delete {
  display: none;
}
.delete-icon {
  display: none;
}
.textColor{
  color: #435D72;
}
.btnyes{
  background-color: #16639d;
  
}
.btnno{
  background-color: #38516f;
}
.btnopen{
  background-color: #c0d1c2;
}
.xx{
  background:#435D72 ;
  background-color: red;
}
label{
  font-size: medium;
  font-weight: 500;
  
  
}
.dwPlXY:not(:last-of-type)>div:nth-child(2){
  border-bottom-color: #55dedc;
  border-bottom-width: 5px;
  
}
.calendar_default_corner > div:nth-child(2), .scheduler_default_corner > div:nth-child(2) {
  background: rgb(243, 243, 243) !important;
    color: transparent !important;
}

.react-datepicker__month-container >.react-datepicker__month-container{
z-index: 1000;
}

@import url("./assets/css/bootstrap.min.css");
.form-control{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -7px;
 
}
.picker {
  
 
outline: none;
 
}
.hide-bg{
  background-color:#F2F2F2;
}
.text-hover{
  
}
.text-hover:hover{
  color: rgb(113, 113, 238);
}
.jbxLgY{
  padding: 18px !important;
  background-color: rgb(229, 113, 113)!important;
  padding-left: 18px !important;
  border-radius: 90px !important;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 250;
}
.sc-cabOPr{
  padding: 18px !important;
  background-color: rgb(229, 113, 113)!important;
  padding-left: 18px !important;
  border-radius: 90px !important;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: -1000;
}


.tboard>a{
  background-color: red;
}
.rdt_TableHeadRow {
  border-radius: 10%;
}
.input{
  outline: none;
}


.tboardgrid  .rdt_TableRow > .sc-hLBbgP:first-child {
  -webkit-flex-grow: 0 !important;
  min-width: 44px;
  /* background-color: purple; */
}


.tboardgrid .rdt_TableHeadRow > .sc-hLBbgP:nth-child(1) {
  padding: 0px 0px 0px 15px;
  -webkit-flex-grow: 0 !important;
  min-width: 50px;
  }
.tboardgrid .rdt_TableHeadRow > .sc-hLBbgP:nth-child(2) {
  /* background-color: purple; */
  padding: 0px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(3) {
  padding: 0px 0px 0px 9px;
  /* background-color: purple; */
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(4) {
 
  padding: 0px 0px 0px 9px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(5) {
 
  padding: 0px 0px 0px 9px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(6) {
 
  /* padding: 0px 0px 0px 9px; */
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(7) {
 
  padding: 0px 0px 0px 9px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(8) {
 
  padding: 0px 0px 0px 9px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(9) {
 
  padding: 0px 0px 0px 9px;
}
.rdt_TableHeadRow > .sc-hLBbgP:nth-child(10) {
 
  padding: 0px 0px 0px 9px;
} 
.imageUpload .jbxLgY {
  z-index: 250 !important; 
}
.select-no-margin .css-1pahdxg-control {
  margin-bottom: 0;
}

.profileImage > div {
  z-index: 500;
}
.chart-bg{
  background-color: #B3E9F5;
}
.chart-bg-success{
  background-color: rgb(0, 255, 162);
}

.checkbox-label{
  font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #212529;
    text-align: left;
    margin-top: 5px;
}


