*{
  margin: 0;
  padding:0;
}

body {
  position: relative;
  background: rgb(237, 232, 241) !important;
  /* background: transparent linear-gradient(92deg, rgba(246, 245, 248, 1) 0%, rgba(226, 242, 240, 1) 42%, rgba(199, 238, 230, 1) 64%, rgba(228, 214, 240, 1) 100%) 0% 0% no-repeat padding-box !important; */
  /* opacity: 1 ;  */
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  
}
.forbbiden{
  background: rgb(105, 188, 188) !important;
}
::-webkit-scrollbar{
  width: 3px;
  
 
}

::-webkit-scrollbar-thumb{
  background-color: #01693B;
  border-radius: 10px;
  scroll-behavior: smooth;
  scrollbar-width:inherit;
 
  
  
  
}
.sidebar{
  position:fixed;
  top: 0%;
  left: 0%;
  width: 250px;
  height: 100%;
  background-color: #eef4f5;
  display: flex;
  flex-direction: column;
  /* background: transparent linear-gradient(92deg, rgba(246, 245, 248, 1) 0%, rgba(226, 242, 240, 1) 42%, rgba(199, 238, 230, 1) 64%, rgba(228, 214, 240, 1) 100%) 0% 0% no-repeat padding-box;
  opacity: 1; */

}
.scrollbox{
  overflow-y: auto;
  overflow-x: hidden;
  padding: auto;
  
}
.logo{
  display:flex;
 
 color: #757575;
   
    font-size: 16px;
    font-weight: 600;
   gap: 10px;
   /* margin: 8px ; */
   padding: 8px;
   
}
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* Set the width of the scrollbar */
  scrollbar-color: #999999 #ffffff; /* Set the color of the scrollbar track and thumb */
}
.table-container::-webkit-scrollbar {
  width: 6px; 
}
.scrollbox-inner{
 
  font-size: 14px;

}
.logo:hover{
  background-color: rgb(203, 238, 231);
  
}
.nav{
  margin-top: -10px;
  margin-left: -10px;
}
a:hover,
a:focus {
  text-decoration: none;
}
.menu:hover{
  background-color:  #cbeee7;
  
}

.form-login{
  
  padding: 30px;
  border-radius: 15px;
 margin-left: 10px;
 margin-right: 10px;
 
}

.Count{
  
   
    border-radius: 15px;
    color: "#fffff";
    font-style: "normal";
    font-weight: 700,;
    font-size: "14px";
    line-height: "17px";
 
}
.CountIcon{
  height: "20px";
   width: "6px"; left: "37.5%";
  right: "37.5%";
  top: "8.33%";
  bottom: "8.33%";
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
  
}

#sidebar-wrapper {
 
  position: fixed;
  left: 0px;
  height:88%;
  width: 300px;
  scrollbar-width: thin;
  background: #eef4f5;
  transition: all 0.5s ease;
  
}

#wrapper.toggled #sidebar-wrapper {
  width: 300px;
  
}

.sidebar-user {
  position: absolute;
  top: 0;
  width: 300px;
 left: 30px;
 
}

.sidebar-brand {
  position: absolute;
  bottom: 0;
  width: 100%;
   padding:20px 0 ;
 text-align: center;
 
}
.sidebar-user img{
  width: 50px;
  border-radius: 50%;
}
.sidebar-brand p{
 color: #757575;
 margin-top: -5px;
}

.sidebarImage{
  position: absolute;
  bottom: 0px;
  width: 250px;
  background: #eef4f5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y:hidden;
}
.sidebar-nav {
  position: absolute;
  top: 75px;
  width: 250px;
  height: 565px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y:scroll;
  overflow-x: hidden;
}

.sidebar-nav > li {
  text-indent: 5px;
  line-height: 33px;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #757575;
  font-weight: 600;
  font-size: 14px;
}
.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #000;
  background: #cbeee7;
  
}
.sidebar-nav > li > a i.fa {
      font-size: 16px;
    margin-left: 2px;
    width: 30px;
}
.page-bg{
  background: linear-gradient(#EEF4F5,#E4D6F0,#EEF4F5);
 max-height: max-content;
}
#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
#navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  font-size: 20px;
  margin-bottom: 0;
  border-radius: 0;
}

.nav-color{
  border-width: 0 0 0 0;
  background-color: #d69191;
  font-size: 16px;
  margin-bottom: 0;
  border-radius: 0;
}
#navbar-wrapper .navbar a {
  color: #757575;
}
#navbar-wrapper .navbar a:hover {
  color: #F8BE12;
}

#content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}
#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.nav-img{
  background-color: #eef4f5;
  position:fixed;
  bottom:0px;
  left:0px;
  width: 245px;
  height:90px
}
.img{
  width: 191px;
}
/* .img1{
  width: 250px;
  height: 100px;
} */
.position-absolute-toggole{
  position: absolute;
  top:29px; left: 14.9rem;
  cursor: pointer
}

.remove-Link:hover {
  text-decoration: none; /* Remove underline and link styling on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
  background-color: red;
}
::-webkit-scrollbar {
  width: 2px;
  border: 1px solid #f1f6f4 !important;
  height: 2px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #f1f4f3 !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #01693B !important;
}
@media (min-width: 992px) {
  .img1{
    width: 250px;
    height: 100px;
  }
  .dtqzBx div:first-child {
    font-size: 14px !important;
    white-space: nowrap  !important;
    text-overflow: ellipsis  !important;
    overflow: hidden  !important;
   
  } 
  .img1{
    width: 250px;
   height: 100px;;
    
  }
  .rdt_TableHeadRow {
    border-radius: 0%;
}
  #wrapper {
    padding-left: 250px;
  }
  .nav-img{
    background-color: #eef4f5;
    position:fixed;
    bottom:0px;
    left:0px;
    max-width: 100%;
    height:90px
  }
  #wrapper.toggled {
    padding-left: 60px;
  }

  #sidebar-wrapper {
    width: 250px;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 70px;
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 250px;
    height:36rem;
    
    list-style: none;
    padding-bottom: 10px;
    overflow-y:scroll;
    overflow-x: hidden;
  }
  
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 60px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 60px;
  }
}

@media screen and (max-width:15in) {
  .img1{
    width: 200px;
    height: 100px;
  }
  .bQdYbR {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
}
  .dgGdqu {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
  
  .btn-add {
    background-color: #ade8f7 !important;
    color: #246a81 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    border-radius: 10px ;
}
.btn-request {
  background-color: #f7cdad;
  color: #814b24;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-bg {
  background-color: #C7EEE6;
  color: green;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-done {
  background-color: #90efdc;
  color: rgb(87, 96, 87);
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
  h5{
    font-size: 16px !important;
  }
  .font-sm-14{
    font-size: 14px !important;
  }
  
  .font-sm-15{
    font-size: 15px !important;
  }
  .font-sm-12{
    font-size: 12px !important;
  }
 
  .font-sm-13{
    font-size: 13px !important;
  }
  .position-absolute-toggole{
    position: absolute;
    top:29px; left:11.5rem;
    cursor: pointer
  }
  .iSAVrt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    background-color: #FFFFFF;
    min-height: 45px !important;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-style: solid;
}
  .btn-pending {
    background-color: #f5f7ad;
    color: #7c8124;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
  }
  .headCells {
    font-size: 12px !important;
    font-weight: 400;
    
    text-transform: uppercase;
      
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 14px;}
  .btn-bg {
    background-color: #C7EEE6;
    color: green;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
}
.btn-bgg{
  background-color: #F7B2AD;
  color: #DF5D53;
  font-size: 13px !important;
  font-weight: 500;
  border-radius:10px;
}
.sc-hHTYSt .dOIzqm .rdt_TableBody>.cell{
  font-size: 14px !important;
  font-weight: 500;
}
.dtqzBx div:first-child {
  font-size: 12px !important;
  white-space: nowrap  !important;
  text-overflow: ellipsis  !important;
  overflow: hidden  !important;
}
/* .rdt_TableHeadRow {
  border-radius: 0%;
} */
.kVrXuC{
  max-width: 100%;
}


  .img{
    width: 191px;
  }
  .nav-img{
    background-color: #eef4f5;
    position:fixed;
    bottom:0px;
    left:0px;
    width: 200px !important;
    height:80px
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 200px;
    height:30rem;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y:scroll;
    overflow-x: hidden;
    background-color: #eef4f5;
  }
  #wrapper.toggled #navbar-wrapper {  
    position: absolute;
   
}
  #sidebar-wrapper {
    width: 200px;
    height: 86%;
  }
 #wrapper {
    padding-left: 200px;
}
  .sidebar-nav > li {
    text-indent: 2px;
    line-height: 28px;
  }
  .sidebar-nav > li a {
    display: block;
    text-decoration: none;
    color: #757575;
    font-weight: 600;
    font-size: 12px;
  }
}
.eye-icon{
  position:absolute;
  right: 4.3rem;
  top: 10.2rem;
}
 @media screen and (max-width:14in) {
  .img1{
    width: 200px;
    height: 100px;
  }
  .bQdYbR {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
}
  .dgGdqu {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
  
  .btn-add {
    background-color: #ade8f7 !important;
    color: #246a81 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    border-radius: 10px ;
}
.btn-request {
  background-color: #f7cdad;
  color: #814b24;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-bg {
  background-color: #C7EEE6;
  color: green;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-done {
  background-color: #90efdc;
  color: rgb(87, 96, 87);
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
  h5{
    font-size: 16px !important;
  }
  .font-sm-14{
    font-size: 14px !important;
  }
  
  .font-sm-15{
    font-size: 15px !important;
  }
  .font-sm-12{
    font-size: 12px !important;
  }
 
  .font-sm-13{
    font-size: 13px !important;
  }
  .position-absolute-toggole{
    position: absolute;
    top:29px; left:11.5rem;
    cursor: pointer
  }
  .iSAVrt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    background-color: #FFFFFF;
    min-height: 45px !important;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-style: solid;
}
  .btn-pending {
    background-color: #f5f7ad;
    color: #7c8124;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
  }
  .headCells {
    font-size: 12px !important;
    font-weight: 400;
    
    text-transform: uppercase;
      
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 14px;}
  .btn-bg {
    background-color: #C7EEE6;
    color: green;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
}
.btn-bgg{
  background-color: #F7B2AD;
  color: #DF5D53;
  font-size: 13px !important;
  font-weight: 500;
  border-radius:10px;
}
.sc-hHTYSt .dOIzqm .rdt_TableBody>.cell{
  font-size: 14px !important;
  font-weight: 500;
}
.dtqzBx div:first-child {
  font-size: 12px !important;
  white-space: nowrap  !important;
  text-overflow: ellipsis  !important;
  overflow: hidden  !important;
}
/* .rdt_TableHeadRow {
  border-radius: 0%;
} */
.kVrXuC{
  max-width: 100%;
}

  .img{
    width: 191px;
  }
  .navlogo{
    background-color:  rgb(203, 238, 231);
  }
  .nav-img{
    background-color: #eef4f5;
    position:fixed;
    bottom:0px;
    left:0px;
    width: 200px !important;
    /* height:40px !important; */
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 200px;
    height:30rem;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y:scroll;
    overflow-x: hidden;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
   
}
  #sidebar-wrapper {
    width: 200px;
    height: 86%;
  }
 #wrapper {
    padding-left: 200px;
}
  .sidebar-nav > li {
    text-indent: 2px;
    line-height: 28px;
  }
  .sidebar-nav > li a {
    display: block;
    text-decoration: none;
    color: #757575;
    font-weight: 600;
    font-size: 12px;
  }
}
@media screen and (max-width:13in) {
  .img1{
    width: 200px;
    height: 100px;
  }
  .bQdYbR {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
}
  .dgGdqu {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 130px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
  
  .btn-add {
    background-color: #ade8f7 !important;
    color: #246a81 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    border-radius: 10px ;
}
.btn-request {
  background-color: #f7cdad;
  color: #814b24;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-bg {
  background-color: #C7EEE6;
  color: green;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
.btn-done {
  background-color: #90efdc;
  color: rgb(87, 96, 87);
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 10px;
}
  h5{
    font-size: 16px !important;
  }
  .font-sm-14{
    font-size: 14px !important;
  }
  
  .font-sm-15{
    font-size: 15px !important;
  }
  .font-sm-12{
    font-size: 12px !important;
  }
 
  .font-sm-13{
    font-size: 13px !important;
  }
  .position-absolute-toggole{
    position: absolute;
    top:29px; left:11.5rem;
    cursor: pointer
  }
  .iSAVrt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    background-color: #FFFFFF;
    min-height: 45px !important;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.12);
    border-bottom-style: solid;
}
  .btn-pending {
    background-color: #f5f7ad;
    color: #7c8124;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
  }
  .headCells {
    font-size: 12px !important;
    font-weight: 400;
    
    text-transform: uppercase;
      
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 14px;}
  .btn-bg {
    background-color: #C7EEE6;
    color: green;
    font-size: 13px !important;
    font-weight: 500;
    border-radius: 10px;
}
.btn-bgg{
  background-color: #F7B2AD;
  color: #DF5D53;
  font-size: 13px !important;
  font-weight: 500;
  border-radius:10px;
}
.sc-hHTYSt .dOIzqm .rdt_TableBody>.cell{
  font-size: 14px !important;
  font-weight: 500;
}
.dtqzBx div:first-child {
  font-size: 12px !important;
  white-space: nowrap  !important;
  text-overflow: ellipsis  !important;
  overflow: hidden  !important;
}
/* .rdt_TableHeadRow {
  border-radius: 0%;
} */
.kVrXuC{
  max-width: 100%;
}


  .img{
    width: 191px;
  }
  .nav-img{
    background-color: #eef4f5;
    position:fixed;
    bottom:0px;
    left:0px;
    width: 200px !important;
    height:90px
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 200px;
    height:30rem;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y:scroll;
    overflow-x: hidden;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
   
}
  #sidebar-wrapper {
    width: 200px;
    height: 86%;
  }
 #wrapper {
    padding-left: 200px;
}
  .sidebar-nav > li {
    text-indent: 2px;
    line-height: 28px;
  }
  .sidebar-nav > li a {
    display: block;
    text-decoration: none;
    color: #757575;
    font-weight: 600;
    font-size: 12px;
  }
} 
@media (min-width: 768px) and (max-width: 991px) {
  .img1{
    width: 200px;
    height: 100px;
  }
  #wrapper {
    padding-left: 60px;
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 200px;
    
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y:scroll;
    overflow-x: hidden;
  }
  #sidebar-wrapper {
    width: 60px;
  }
  
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

@media (max-width: 768px) {
  .img1{
    width: 200px;
    height: 100px;
  }
  #wrapper {
    padding-left: 0;
  }
  .sidebar-nav {
    position: absolute;
    top: 75px;
    width: 200px;
   
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y:scroll;
    overflow-x: hidden;
  }
  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 300px;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}


.rows{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}

.top-search{
    margin-left: 13px;
   }

  .form-control{
    background-color: #dadada;
    margin-bottom: 10px;
   }
   .modal-control {
    display: block;
    width: 50%;
    height: calc(1.35em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
   .search-control{
    background-color: #E5F2FA;
    margin-bottom: 10px;
   }

   .table{
    margin-top:40px;
    margin-left: 5px;
   }
   

   .time-head{
    width: 250px;
   }
   .lg-head{
    width: 400px;
   }

   .text-table-top{
    font-weight: 700;
    color: #000;
   }
   .table-p{
    color: #898787;
    font-weight: 600;
    font-size: 12px;

   }
   .table-p img{
    width: 16px;
   }
   td{
    
    color: #828290;
   }
   td i{
    
    color: #000;
   }
.btn-bg{
  background-color: #C7EEE6;
  color: green;
  font-size: 15px;
  font-weight: 500;
  border-radius:10px; 
}
.btn-done{
  background-color: #90efdc;
  color: rgb(87, 96, 87);
  font-size: 15px;
  font-weight: 500;
  border-radius:10px; 
}

.btn-bg1{
  background-color: #C7EEE6;
  color: green;
  /* font-size: 15px; */
  font-weight: 500;
  border-radius:10px; 
}
.btn-requried{
  background-color: #a1e1ee;
  /* color: white; */
  font-size: 15px;
  font-weight: 500;
  border-radius:10px; 
}
.headCells{
           font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            font-family:-apple-system, BlinkMacSystemFont, Segoe UI,Roboto , Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            /* fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"; */
            /* background-color:#C7EEE6; */
            /* color:green; */
}
/* @media all {
  .page-break { display: none; }
  }
   */
  @media print {
  .page-break { display: block; page-break-before: always; }
  }
.btn-tab{
  background-color: #e9f3f1;
  /* color: green; */
  font-size: 15px;
  font-weight: 500;
  border-radius:10px; 
}
.btn-bundle{
  background-color: #37B78B;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.60rem;
  
}
.btn-blue{
  background-color: #678ef9;

  color: rgb(14, 31, 66);
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.60rem;  
}
.btn-green{
  background-color: #c9f780;
  /* background-color: #4d7752; */
  /* background-color: #6e9b73; */

  color: rgb(53, 128, 43);
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.60rem;
}
.btn-pink{
  background-color: #f486d6;
  /* background-color: #4d7752; */
  /* background-color: #6e9b73; */

  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.60rem;

}
.btn-bgg{
  background-color: #F7B2AD;
  color: #DF5D53;
  font-size: 15px;
  font-weight: 500;
  border-radius:10px;
}
.btn-add{
  background-color: #ade8f7;
  color: #246a81;
  font-size: 15px;
  font-weight: 500;
  border-radius:10px;
}
.btn-pending{
  background-color: #f5f7ad;
  color: #7c8124;
  font-size: 15px;
  font-weight: 500;
  border-radius:10px;
}
.btn-request{
  background-color: #f7cdad;
  color: #814b24;
  font-size: 15px;
  font-weight: 500;
  border-radius:10px;
}

.text-heading {
  background: #EAEAEA;
  /* font-family: "Catamaran" */
  font-family:"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
  ;
  
  font-size: large;
  border-radius: 6px;
  font-size: 1.1rem;
    padding: 0.5rem 1rem;
    font-weight: 700;
    color: #000;
    background: #EAEAEA;
    
}

input::placeholder {

  font-family:'Barlow, Bold' ,
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}


.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}
.ms-half {
  margin-left: 12rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.accordion {
  margin-bottom: .5rem;
  border: 0px solid #f0f5f8;
  background: #fff;
}
.accordion .accordion-header {
  background: #ffffff;
  position: relative;
  width: 100%;
}
.accordion .accordion-header h5 {
  font-size: .8rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #565656;
  background: #EAEAEA;
  border-radius: 6px;
}
.accordion .accordion-header h5 a {
  display: block;
}
.accordion .accordion-header h5 a:after {
  position: absolute;
  right: 1rem;
  font-family: icons;
  content: "\e944";
}
.accordion .accordion-header h5 a.collapsed:after {
  content: "\e946";
}
.accordion .accordion-header h4 {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #000;
  background: #EAEAEA;
  border-radius: 6px;
}
.accordion .accordion-header h4 a {
  display: block;
}
.accordion .accordion-header h4 a:after {
  /* position: absolute;
  right: 1rem;
  font-family: icons;
  content: "\e940";
  font-size: .9rem;
  top: 11px */

}
.accordion .accordion-header h4 a.collapsed:after {
  /* content: "\e944"; */
}
.accordion .accordion-body {
  padding: 1rem;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.collapse:not(.show) {
  display: none;
}
.zindex{
  z-index: 2;
  position:absolute
}
.zindex2{
  z-index: 0;
  position:relative
}

.price-align{
  text-align: right;
  float: right;
  align-items:flex-end;
  justify-content:right;
  width: "100%";
}

header .navbar-brand {}

.nav-center-menu {
  width: 500px;
}

.nav-center-menu ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.nav-center-menu ul li a {
  display: block;
  font-weight: 600;
  padding: 1.2rem 1rem;
}
.nav-center-menu ul li a {
  padding: 1.2rem 1rem;
  display: block;
  font-weight: 600;
  color: #19417e;
  cursor: pointer;
}

.nav-center-menu ul li a small {
  display: block;
  font-weight: 300;
  color: #777887;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.nav-center-menu ul li a.active,
.nav-center-menu ul li a:hover {
  background: #dff4ef;
  color: #232b29;
}

.navbar-brand {
  font-size: 1.25rem;
  margin-right: 1rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
  text-decoration: none;
  white-space: nowrap;
}

header {
  background: #fff;
  justify-content: space-between;
  padding: 0 3rem;
  align-items: center;
    /* box-shadow: 4px 0 5px #d3e0e8; */
    display: flex;
    flex-flow: row;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    /* z-index: 999; */
}
header .navbar-brand {
  display: block;
  width: 300px;
}

header .header-right {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: flex-end;
  margin: 0;
  width: 440px;
}
header .header-right ul {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin: 0;
}
header .header-right ul li {
  display: block;
  position: relative;
}
.client-container{
  margin-top: 6%;
}
.labelColor {
  color: #202676;
  font-size: 1rem;
  font-size:larger;
}

header .header-right ul li .header-dropdown {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 4px #b3b3b3;
  display: none;
  min-width: 10rem;
  position: absolute;
  right: 0;
}
li{
  list-style-type:none;
  }

header .header-right ul li .header-dropdown a {
  border-bottom: 0 solid #e4e4e4;
  padding: 0.4rem 0.7rem;
  text-align: left;
}
header .header-right ul li a {
  align-items: center;
  color: #646479;
  display: flex;
  padding: 0.7rem 0.4rem;
  position: relative;
  width: 100%;
}
header .header-right ul li .header-dropdown a:hover {
  color: #f01212;
}

header .header-right ul li:hover .header-dropdown {
  display: block;
}
.Cards{
  /* background-color: #eee; */
}
.Cards:hover{
  /* color:#cbeee7; */
  
  background-color:#e1f7f3 ;
  
}

.profile-list-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 1rem;
}
.pointer{
  cursor:pointer;
}

.price{
  margin-right: 0px;
  width: 70px;
  text-align: right;
}

.label-light{
  color: #767578;
  font-size:medium;
}

.label-dark{
  color: #435D72;
  font-size: large;
  font-weight: 500;
  margin-bottom: 5px;
}

.address-small-font{
  font-size: 14px;
}

.patient-font{
  font-weight: 600;
}

.remove-bottom{
  margin-bottom: -20px;
}

.zero-bottom{
  margin-bottom: 0px;
}
.remove-top{
  margin-top: -10px;
}
.remove-top2{
  margin-top: -25px;
}
hr{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.input {
  border: black solid 2px;
}
.input:focus{
  outline: none;
}
.border-bottom {
  border-bottom: 2px solid #dee2e6 !important;
}
.kDEcCH {
  padding: 0px;
}
.imageUpload .jbxLgY {
  z-index: 250 !important; 
}
.colorCode{
  padding: 0px;
    width: 25%;
    border: 0px;
    background-color:white;
    border-radius: "";
}

.warningBorder{
  border: 2px solid #ffc107 !important;
}
div:has(.justifyLeftContent) {
  justify-content: left !important;
}
.img {
  width: 75%;
}
.loginpagebg{
  background: transparent linear-gradient(90deg, #F6F5F8 0%, #E2F2F0 42%, #C7EEE6 64%, #E4D6F0 100%) 0% 0% no-repeat padding-box;;

  /* background-color: #006837; */
}
.button{
  background: transparent linear-gradient(92deg, rgba(228, 214, 240, 1) 0%, rgba(199, 238, 230, 1) 42%, rgba(226, 242, 240, 1) 64%, rgba(246, 245, 248, 1) 100%) 0% 0% no-repeat padding-box;
}
/*For patient grid*/
div.rdt_TableCell:has(div.patientNameClass){
 padding-left: 0px;
}
.modal-body-scrollable {
  max-height: calc(100vh - 200px); /* Adjust the value as needed */
  overflow-y: auto;
}